<template>
  <div>
    <spinner-loader :loading="loading" />

    <suggestion-form
      v-if="!loading"
      :submit="submit"
      :suggestion="suggestion"
    />

  </div>
</template>

<script>
import SuggestionForm from '@/common/components/OrganizationSuggestions/SuggestionForm.vue'
import SpinnerLoader from '@/common/components/common/Table/SpinnerLoader.vue'
import handleAlerts from '@/common/compositions/common/handleAlerts'

export default {
  name: 'OrgSuggestionEdit',
  components: { SuggestionForm, SpinnerLoader },
  data() {
    return {
      suggestion: { address: {} },
      loading: false,
      publish: true,
      rejectionReason: '',
    }
  },
  computed: {
    suggestionClass() {
      return this.suggestion?.classificationsNames?.[0]
    },
  },
  created() {
    this.getSuggestion()
  },
  setup() {
    const { successfulOperationAlert } = handleAlerts()
    return { successfulOperationAlert }
  },
  methods: {
    getSuggestion() {
      this.loading = true
      this.$entities.get(`internalops/entity/${this.$route.params.id}`).then(res => {
        this.suggestion = {
          ...res.data.data,
          media_files: res.data.data.media_files.map(image => image.url),
          address: {
            ...res.data.data.address[0],
            lat: res.data.data.location.coordinates[1],
            lng: res.data.data.location.coordinates[0],
          },
        }
      }).finally(() => {
        this.loading = false
      })
    },
    submit() {
      return this.$entities.post(`internalops/entity/${this.$route.params.id}?_method=PUT`, {
        ...this.suggestion,
        ...this.suggestion.address,
        subclassifications: this.suggestion.subClassifications_id,
        classifications: this.suggestion.classification_id,
      })
        .then(() => {
          this.successfulOperationAlert('Suggestion is updated Successfully')
          this.$router.back()
        }).catch(err => {
          const text = err.response.data.errors.name?.[0]
          if (text.includes('Entity with same')) {
            const numberRegex = /\d+/

            const match = text.match(numberRegex)
            const extractedNumber = match ? parseInt(match[0], 10) : null
            this.$router.push({ name: 'organization-suggestion-edit', params: { id: extractedNumber } })
          }
        })
    },
  },
}
</script>

<style>

</style>
